import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Grid,
  Typography,
} from '@mui/material';
import {
  CircularProgressWithLabel,
  LinearProgressWithLabel,
  Footer,
  Header
} from '../components/index.js';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { read, write } from '../utilities/Storage.js';
import { forward, back } from '../utilities/Navigation.js';

const KEY = 'balance';

export default function Balance() {
  let navigate = useNavigate();
  let [value, setValue] = useState('');
  let [error, setError] = useState(false);

  const next = () => {
    if (value) {
      write(KEY, value);
      navigate(forward(KEY));
      return;
    }
    setError(true);
  };

  useEffect(() => {
    let v = read(KEY) || '';
    setValue(v);
  }, []);

  return (
    <Container disableGutters>
      <Header />
      <Grid container direction="row" alignItems="center" justifyContent="center" sx={{my: 3}}>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={22} />
        </Box>
        <CircularProgressWithLabel value={22} />
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography align="center" variant="h6" sx={{m: 2}}>
              Please enter your mortgage balance
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{maxWidth: '500px', mr: 2 }}
        >
          <Grid item xs>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                label="Amount"
                value={value}
                onChange={(e) => {
                  if (e.target.value) {
                    setError(false);
                  }
                  let newValue=e.target.value.replace(/,/g, "");
                  let commaValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  setValue(commaValue);
                }}
              />
            </FormControl>
          </Grid>
          {error && (
            <Grid item xs={12} sx={{mb: 2, mt: 2}}>
              <Typography color='error'>
                Please enter your mortgage balance
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{mt: 5, mx: 1}}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button variant="outlined" onClick={() => navigate(back(KEY))} fullWidth>
                    Back
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button variant="contained" color="error" onClick={next} fullWidth>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
}

import { read } from '../utilities/Storage.js';

const buypages = [
  '/loantype',
  '/homedescription',
  '/propertyuse',
  '/timeframe',
  '/firsttime',
  '/military',
  '/realestate',
  '/purchaseprice',
  '/downpayment',
  '/assistance',
  '/credit',
  '/employment',
  '/latepayments',
  '/foreclosure',
  '/bankruptcy',
  '/name',
  '/contact',
  '/thanks',
];

const refipages = [
  '/loantype',
  '/homedescription',
  '/propertyuse',
  '/whyrefinance',
  '/homevalue',
  '/balance',
  '/military',
  '/credit',
  '/second',
  '/employment',
  '/bankruptcy',
  '/name',
  '/contact',
  '/thanks',
];

const cashpages = [
  '/loantype',
  '/homedescription',
  '/propertyuse',
  '/homevalue',
  '/balance',
  '/cash',
  '/military',
  '/credit',
  '/second',
  '/employment',
  '/bankruptcy',
  '/name',
  '/contact',
  '/thanks',
]

function getPages() {
  let loanType = read('loantype');
  switch (loanType) {
    case 0:
      return refipages;
    case 1:
      return buypages;
    case 2:
      return cashpages;
    default:
      return buypages;
  }
}

function forward(key) {
  let pages = getPages();
  let x = pages.findIndex((p) => p === `/${key}`)
  return pages[x + 1];
}

function back(key) {
  let pages = getPages();
  let x = pages.findIndex((p) => p === `/${key}`);
  if (x <= 0) {
    return pages[0];
  }
  return pages[x - 1];
}

export { forward, back };
import {
  Box,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

function Footer() {
  return (
    <Grid container sx={{mt: 5}}>
      <Grid item xs={12} sx={{border: '1px solid black', mb: 2}}></Grid>
      <Grid item xs={6}>
        <Typography variant='subtitle2'>
          Moses Family Loans<br />
          NMLS: #2297967<br />
          Juncture Mortgage<br />
          2 Westowne Dr<br />
          Liberty, MO 64068
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Link href='https://mosesfamilyloans.com' target='_blank'>
          <Typography variant='subtitle2'>Home</Typography>
        </Link>
        <Link href='https://mosesfamilyloans.com/about' target='_blank'>
          <Typography variant='subtitle2'>About Us</Typography>
        </Link>
        <Link href='https://mosesfamilyloans.com/privacy-policy' target='_blank'>
          <Typography variant='subtitle2'>Privacy Policy</Typography>
        </Link>
        <Link href='https://mosesfamilyloans.com/contact' target='_blank'>
          <Typography variant='subtitle2'>Contact Us</Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

export default Footer;
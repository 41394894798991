import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import {
  BigButton,
  CircularProgressWithLabel,
  LinearProgressWithLabel,
  Footer,
  Header
} from '../components/index.js';
import { CheckCircleOutline, HighlightOff} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { read, write } from '../utilities/Storage.js';
import { forward, back } from '../utilities/Navigation.js';

const YES = 0;
const NO = 1;
const KEY = 'military';

export default function Military() {
  let navigate = useNavigate();
  let [selected, setSelected] = useState();
  let [error, setError] = useState(false);
  let getVariant = (type) => {
    if (type === selected) return 'contained';
    return 'outlined'
  }

  const choose = (type) => {
    setError(false);
    setSelected(type);
    write(KEY, type);
  }

  const next = () => {
    if (typeof(selected) === 'number') {
      navigate(forward(KEY));
      return;
    }
    setError(true);
  };

  useEffect(() => {
    setSelected(read(KEY));
  }, []);

  return (
    <Container disableGutters>
      <Header />
      <Grid container direction="row" alignItems="center" justifyContent="center" sx={{my: 3}}>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={31} />
        </Box>
        <CircularProgressWithLabel value={31} />
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography align="center" variant="h6" sx={{m: 2}}>
              Active or previous U.S. military service?
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{maxWidth: '500px'}}
        >
          <Grid item xs>
            <BigButton
              icon={<CheckCircleOutline fontSize='large' />}
              label="Yes"
              variant={getVariant(YES)}
              onClick={() => choose(YES)}
            />
          </Grid>
          <Grid item xs>
            <BigButton
              icon={<HighlightOff fontSize='large' />}
              label="No"
              variant={getVariant(NO)}
              onClick={() => choose(NO)}
            />
          </Grid>
          {error && (
            <Grid item xs={12} sx={{mb: 2, mt: 2}}>
              <Typography color='error'>
                Please choose one
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{mt: 5}}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button variant="outlined" onClick={() => navigate(back(KEY))} fullWidth>
                    Back
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button variant="contained" color="error" onClick={next} fullWidth>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
}

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import {
  BigButton,
  Footer,
  Header
} from '../components/index.js';
import { CurrencyExchange, Home, Loop} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { read, write } from '../utilities/Storage.js';
import { forward } from '../utilities/Navigation.js';

const REFINANCE = 0;
const BUY = 1;
const CASH_OUT = 2;
const KEY = 'loantype';

function LoanType() {
  let navigate = useNavigate();
  let [selected, setSelected] = useState();
  let [error, setError] = useState(false);
  let getVariant = (type) => {
    if (type === selected) return 'contained';
    return 'outlined'
  }

  const choose = (type) => {
    setError(false);
    setSelected(type);
    write(KEY, type);
  }

  const next = () => {
    if (typeof(selected) == 'number') {
      navigate(forward(KEY));
      return;
    }
    setError(true);
  };

  useEffect(() => {
    const date = new Date()
    write('lastvisit', date.toLocaleString("en-US"));
    setSelected(read(KEY));
  }, []);

  return (
    <Container justify="center">
      <Header />
      <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={12} sx={{m: 2}}>
          <Typography align="center" variant="h6">
            We’re Taking The “Complicated” Out Of The Mortgage Equation And Adding Ease And Simplicity
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h6" sx={{m: 2}}>
            Loan Type
          </Typography>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{maxWidth: '500px'}}
        >
          <Grid item xs>
            <BigButton
              icon={<Loop fontSize={'large'} />}
              label={<div>Refinance a<br/>home</div>}
              variant={getVariant(REFINANCE)}
              onClick={() => choose(REFINANCE)}
            />
          </Grid>
          <Grid item xs>
            <BigButton
              icon={<Home fontSize={'large'} />}
              label={<div>Buy a new<br/>home</div>}
              variant={getVariant(BUY)}
              onClick={() => choose(BUY)}
            />
          </Grid>
          <Grid item xs>
            <BigButton
              icon={<CurrencyExchange fontSize={'large'} />}
              label={<div>Take cash<br/>out</div>}
              variant={getVariant(CASH_OUT)}
              onClick={() => choose(CASH_OUT)}
            />
          </Grid>
          {error && (
            <Grid item xs={12} sx={{mb: 2}}>
              <Typography color='error'>
                Please choose one
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{mt: 5, mx: 5}}>
            <Button variant="contained" color="error" onClick={next} fullWidth>
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
}

export default LoanType;

import {
  Box,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

function Header() {
  return (
    <Grid container>
      <Grid item xs={6}>
        <img src="/moses-family-loans.png" height="75px" alt="Moses Family Loans" />
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="call"
          >
            <Link href="tel:8163072046" underline="none">
              <PhoneIcon fontSize="large" />
            </Link>
          </IconButton>
          <Link href="tel:8163072046" underline="none">
            <Typography
              variant="h4"
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              (816) 307-2046
            </Typography>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{border: '1px solid black'}}></Grid>
    </Grid>
  );
}

export default Header;
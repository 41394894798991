import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import {
  BigButton,
  CircularProgressWithLabel,
  LinearProgressWithLabel,
  Footer,
  Header
} from '../components/index.js';
import { Apartment, HolidayVillage, Home} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { read, write } from '../utilities/Storage.js';
import { forward, back } from '../utilities/Navigation.js';

const SINGLE_FAMILY = 0;
const MULTI_FAMILY = 1;
const CONDOMINIUM = 2;
const KEY = 'homedescription';

function HomeDescription() {
  let navigate = useNavigate();
  let [selected, setSelected] = useState();
  let [error, setError] = useState(false);
  let getVariant = (type) => {
    if (type === selected) return 'contained';
    return 'outlined'
  }

  const choose = (type) => {
    setError(false);
    setSelected(type);
    write(KEY, type);
  }

  const next = () => {
    if (typeof(selected) === 'number') {
      navigate(forward(KEY));
      return;
    }
    setError(true);
  };

  useEffect(() => {
    setSelected(read(KEY));
  }, []);

  return (
    <Container disableGutters>
      <Header />
      <Grid container direction="row" alignItems="center" justifyContent="center" sx={{my: 3}}>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={6} />
        </Box>
        <CircularProgressWithLabel value={6} />
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography align="center" variant="h6" sx={{m: 2}}>
              Home Description
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{maxWidth: '500px'}}
        >
          <Grid item xs>
            <BigButton
              icon={<Home fontSize={'large'} />}
              label="Single Family"
              variant={getVariant(SINGLE_FAMILY)}
              onClick={() => choose(SINGLE_FAMILY)}
            />
          </Grid>
          <Grid item xs>
            <BigButton
              icon={<HolidayVillage fontSize={'large'} />}
              label="Multi Family"
              variant={getVariant(MULTI_FAMILY)}
              onClick={() => choose(MULTI_FAMILY)}
            />
          </Grid>
          <Grid item xs>
            <BigButton
              icon={<Apartment fontSize={'large'} />}
              label="Condominium"
              variant={getVariant(CONDOMINIUM)}
              onClick={() => choose(CONDOMINIUM)}
            />
          </Grid>
          {error && (
            <Grid item xs={12} sx={{mb: 2}}>
              <Typography color='error'>
                Please choose one
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{mt: 5}}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button variant="outlined" onClick={() => navigate('/')} fullWidth>
                    Back
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button variant="contained" color="error" onClick={next} fullWidth>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
}

export default HomeDescription;

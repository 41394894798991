import Cookies from 'js-cookie';

function sync() {
  const url = 'https://us-central1-mortgage-landing-page.cloudfunctions.net/sync'
  const body = Cookies.get('approval-quote');
  const parsed = JSON.parse(body);
  const sessionId = parsed['sessionId']

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: body,
    redirect: 'follow'
  };

  fetch(`${url}/${sessionId}`, requestOptions)
    .then(response => response.text())
    .catch(error => console.log('error', error));
}

function write(key, value) {
  const c = Cookies.get('approval-quote');
  const sessionId = crypto.randomUUID()
  let cookie = {
    sessionId: sessionId
  };
  if (c) {
    cookie = JSON.parse(c);
  }

  cookie[key] = value;
  Cookies.set('approval-quote', JSON.stringify(cookie));
  sync()
}

function read(key) {
  const c = Cookies.get('approval-quote');
  let cookie = {};
  if (c) {
    cookie = JSON.parse(c);
  }
  return cookie[key];
}

export { read, write };
import {
  Box,
  Button,
  Grid,
} from '@mui/material';

export default function BigButton ({
  icon,
  label,
  onClick = () => {},
  variant = 'outlined'
}) {
  return (
    <Box align="center">
      <Button
        variant={variant}
        onClick={onClick}
        color="secondary"
        size="large"
        height={'100%'}
        fullWidth
        sx={{p: 1}}
      >
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            {icon}
          </Grid>
          <Grid item xs={12}>
            {label}
          </Grid>
        </Grid>
      </Button>
    </Box>
  );
}
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Grid,
  Typography,
} from '@mui/material';
import {
  CircularProgressWithLabel,
  LinearProgressWithLabel,
  Footer,
  Header
} from '../components/index.js';
import { Phone } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { read, write } from '../utilities/Storage.js';
import { forward, back } from '../utilities/Navigation.js';

const KEY = 'contact';

export default function Contact() {
  let navigate = useNavigate();
  let [error, setError] = useState(false);
  let [phone, setPhone] = useState('');

  const next = () => {
    if (phone) {
      write(KEY, phone);
      navigate(forward(KEY));
      return;
    }
    setError(true);
  };

  useEffect(() => {
    let v = read(KEY) || '';
    setPhone(v);
  }, []);

  return (
    <Container disableGutters>
      <Header />
      <Grid container direction="row" alignItems="center" justifyContent="center" sx={{my: 3}}>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={90} />
        </Box>
        <CircularProgressWithLabel value={90} />
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography align="center" variant="h6" sx={{m: 2}}>
              Phone number
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{maxWidth: '500px', mr: 2, m: 1 }}
        >
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">Phone Number</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start"><Phone /></InputAdornment>}
                label="Phone Number"
                inputProps={{ type:"tel", autoComplete:"tel", inputMode:"tel" }}
                value={phone}
                onChange={(e) => {
                  if (e.target.value) {
                    setError(false);
                  }
                  setPhone(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          {error && (
            <Grid item xs={12} sx={{mb: 2}}>
              <Typography color='error'>
                Please enter your phone
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{mt: 5}}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button variant="outlined" onClick={() => navigate(back(KEY))} fullWidth>
                    Back
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button variant="contained" color="error" onClick={next} fullWidth>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold' }}>Communication Consent:</Typography>
            <Typography>By providing your contact information and clicking the “Agree and Continue” button above, you agree to our Terms of Use and Privacy Policy. You also expressly consent by electronic signature to receive telephonic sales, promotional, marketing and other calls and text messages, including any calls and messages sent by any automated system or other means for the selection or dialing of telephone numbers, or using an artificial or prerecorded voice message when a connection is completed, our partner companies and our marketing partners at the telephone number You have provided, even if that telephone number is on a corporate, state, or national do-not-call list or registry. Your consent and agreement to receive such calls or text messages is not a condition of purchasing any property, goods or services from us, our Family of Companies or any of our partners.</Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { ChartDonutUtilization } from '@patternfly/react-charts';

function CircularProgressWithLabel(props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100px', display: { xs: 'none', md: 'block' } }}
    >
      <ChartDonutUtilization
        allowTooltip={false}
        data={{x: 'a', y: props.value }}
        title={`${props.value}%`}
        labels={() => ''}
        style={{fontSize: '36px'}}
        innerRadius={70}
      />
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;
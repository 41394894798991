import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import {
  BigButton,
  CircularProgressWithLabel,
  LinearProgressWithLabel,
  Footer,
  Header
} from '../components/index.js';
import { Handshake, Store, Savings, PersonSearch} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { read, write } from '../utilities/Storage.js';
import { forward, back } from '../utilities/Navigation.js';

const KEY = 'employment';
const EMPLOYED = 0;
const SELFEMPLOYED = 1;
const RETIRED = 2;
const UNEMPLOYED = 3;

export default function Employment() {
  let navigate = useNavigate();
  let [selected, setSelected] = useState();
  let [error, setError] = useState(false);
  let getVariant = (type) => {
    if (type === selected) return 'contained';
    return 'outlined'
  }

  const choose = (type) => {
    setError(false);
    setSelected(type);
    write(KEY, type);
  }

  const next = () => {
    if (typeof(selected) == 'number') {
      navigate(forward(KEY));
      return;
    }
    setError(true);
  };

  useEffect(() => {
    setSelected(read(KEY));
  }, []);

  return (
    <Container disableGutters>
      <Header />
      <Grid container direction="row" alignItems="center" justifyContent="center" sx={{my: 3}}>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={68} />
        </Box>
        <CircularProgressWithLabel value={68} />
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography align="center" variant="h6" sx={{m: 2}}>
              Employment Status
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Grid
          container
          direction="row"
          spacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="center"
          alignItems="stretch"
          sx={{maxWidth: '500px' }}
        >
          <Grid item xs={6}>
            <BigButton
              icon={<Handshake fontSize='large' />}
              label={<div>Employed</div>}
              variant={getVariant(EMPLOYED)}
              onClick={() => choose(EMPLOYED)}
            />
          </Grid>
          <Grid item xs={6}>
            <BigButton
              icon={<Store fontSize='large' />}
              label={<Typography noWrap>Self Employed</Typography>}
              variant={getVariant(SELFEMPLOYED)}
              onClick={() => choose(SELFEMPLOYED)}
            />
          </Grid>
          <Grid item xs={6}>
            <BigButton
              icon={<Savings fontSize='large' />}
              label={<div>Retired</div>}
              variant={getVariant(RETIRED)}
              onClick={() => choose(RETIRED)}
            />
          </Grid>
          <Grid item xs={6}>
            <BigButton
              icon={<PersonSearch fontSize='large' />}
              label={<Typography noWrap>Not employed</Typography>}
              variant={getVariant(UNEMPLOYED)}
              onClick={() => choose(UNEMPLOYED)}
            />
          </Grid>
          {error && (
            <Grid item xs={12} sx={{mb: 2, mt: 2}}>
              <Typography color='error'>
                Please choose one
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{mt: 5}}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button variant="outlined" onClick={() => navigate(back(KEY))} fullWidth>
                    Back
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button variant="contained" color="error" onClick={next} fullWidth>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
}

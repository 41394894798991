import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  CircularProgressWithLabel,
  LinearProgressWithLabel,
  Footer,
  Header
} from '../components/index.js';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { read, write } from '../utilities/Storage.js';
import { forward, back } from '../utilities/Navigation.js';

const KEY = 'name';

export default function Name() {
  let navigate = useNavigate();
  let [first, setFirst] = useState('');
  let [last, setLast] = useState('');
  let [error, setError] = useState(false);

  const next = () => {
    if (first && last) {
      write('firstname', first);
      write('lastname', last);
      navigate(forward(KEY));
      return;
    }
    setError(true);
  };

  useEffect(() => {
    let f = read('firstname') || '';
    let l = read('lastname') || '';
    setFirst(f);
    setLast(l);
  }, []);

  return (
    <Container disableGutters>
      <Header />
      <Grid container direction="row" alignItems="center" justifyContent="center" sx={{my: 3}}>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={87} />
        </Box>
        <CircularProgressWithLabel value={87} />
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{maxWidth: '500px'}}
        >
          <Grid item xs={12}>
            <TextField fullWidth label="First Name" value={first} onChange={(e) => {
              if (e.target.value) {
                setError(false);
                setFirst(e.target.value);
              }
            }} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Last Name" value={last} onChange={(e) => {
              if (e.target.value) {
                setError(false);
                setLast(e.target.value);
              }
            }} />
          </Grid>
          {error && (
            <Grid item xs={12} sx={{mb: 2, mt: 2}}>
              <Typography color='error'>
                Please enter your name
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{mt: 5}}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button variant="outlined" onClick={() => navigate(back(KEY))} fullWidth>
                    Back
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button variant="contained" color="error" onClick={next} fullWidth>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
}

import {
  Box,
  Button,
  Container,
  Grid,
  Rating,
  Typography,
} from '@mui/material';
import {
  BigButton,
  CircularProgressWithLabel,
  LinearProgressWithLabel,
  Footer,
  Header
} from '../components/index.js';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { read, write } from '../utilities/Storage.js';
import { forward, back } from '../utilities/Navigation.js';

const KEY = 'credit';
const EXCELLENT = 0;
const GOOD = 1;
const AVERAGE = 2;
const BELOW_AVERAGE = 3;
const POOR = 4;

export default function Credit() {
  let navigate = useNavigate();
  let [selected, setSelected] = useState();
  let [error, setError] = useState(false);
  let getVariant = (type) => {
    if (type === selected) return 'contained';
    return 'outlined'
  }

  const choose = (type) => {
    setError(false);
    setSelected(type);
    write(KEY, type);
  }

  const next = () => {
    if (typeof(selected) == 'number') {
      navigate(forward(KEY));
      return;
    }
    setError(true);
  };

  useEffect(() => {
    setSelected(read(KEY));
  }, []);

  return (
    <Container disableGutters>
      <Header />
      <Grid container direction="row" alignItems="center" justifyContent="center" sx={{my: 3}}>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={59} />
        </Box>
        <CircularProgressWithLabel value={59} />
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography align="center" variant="h6" sx={{m: 2}}>
              Your credit profile
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Grid
          container
          direction="row"
          spacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="center"
          alignItems="stretch"
          sx={{maxWidth: '500px' }}
        >
          <Grid item xs>
            <BigButton
              icon={<Rating name="read-only" value={5} readOnly />}
              label={<div>Excellent<br/>720+</div>}
              variant={getVariant(EXCELLENT)}
              onClick={() => choose(EXCELLENT)}
            />
          </Grid>
          <Grid item xs>
            <BigButton
              icon={<Rating name="read-only" value={4} readOnly />}
              label={<div>Good<br/>660-719</div>}
              variant={getVariant(GOOD)}
              onClick={() => choose(GOOD)}
            />
          </Grid>
          <Grid item xs>
            <BigButton
              icon={<Rating name="read-only" value={3} readOnly />}
              label={<div>Avg.<br/>620-659</div>}
              variant={getVariant(AVERAGE)}
              onClick={() => choose(AVERAGE)}
            />
          </Grid>
          <Grid item xs>
            <BigButton
              icon={<Rating name="read-only" value={2} readOnly />}
              label={<div>Below Avg.<br/>580-619</div>}
              variant={getVariant(BELOW_AVERAGE)}
              onClick={() => choose(BELOW_AVERAGE)}
            />
          </Grid>
          <Grid item xs>
            <BigButton
              icon={<Rating name="read-only" value={1} readOnly />}
              label={<div>Poor<br/>&#8804; 579</div>}
              variant={getVariant(POOR)}
              onClick={() => choose(POOR)}
            />
          </Grid>
          {error && (
            <Grid item xs={12} sx={{mb: 2, mt: 2}}>
              <Typography color='error'>
                Please choose one
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{mt: 5}}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button variant="outlined" onClick={() => navigate(back(KEY))} fullWidth>
                    Back
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button variant="contained" color="error" onClick={next} fullWidth>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  HomeDescription,
  FirstTime,
  LoanType,
  Military,
  PropertyUse,
  TimeFrame,
  RealEstate,
  PurchasePrice,
  DownPayment,
  Assistance,
  Credit,
  Employment,
  LatePayments,
  Foreclosure,
  Bankruptcy,
  Name,
  Contact,
  WhyRefinance,
  HomeValue,
  Balance,
  Second,
  Cash,
  Thanks,
} from './views/index';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans',
    button: {
      textTransform: "none"
    }
  }
});

const router = createBrowserRouter([
  { path: "/",                 element: <LoanType />,},
  { path: "/homedescription",  element: <HomeDescription />,},
  { path: "/propertyuse",      element: <PropertyUse />,},
  { path: "/timeframe",        element: <TimeFrame />,},
  { path: "/firsttime",        element: <FirstTime />,},
  { path: "/military",         element: <Military />,},
  { path: "/realestate",       element: <RealEstate />,},
  { path: "/purchaseprice",    element: <PurchasePrice />,},
  { path: "/downpayment",      element: <DownPayment />,},
  { path: "/assistance",       element: <Assistance />,},
  { path: "/credit",           element: <Credit />,},
  { path: "/employment",       element: <Employment />,},
  { path: "/latepayments",     element: <LatePayments />,},
  { path: "/foreclosure",      element: <Foreclosure />,},
  { path: "/bankruptcy",       element: <Bankruptcy />,},
  { path: "/name",             element: <Name />,},
  { path: "/contact",          element: <Contact />,},
  { path: "/whyrefinance",     element: <WhyRefinance />,},
  { path: "/homevalue",        element: <HomeValue />,},
  { path: "/balance",          element: <Balance />,},
  { path: "/second",           element: <Second />,},
  { path: "/cash",             element: <Cash />,},
  { path: "/thanks",           element: <Thanks />,},
  
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import {
  CircularProgressWithLabel,
  LinearProgressWithLabel,
  Footer,
  Header
} from '../components/index.js';

export default function Thanks() {
  return (
    <Container disableGutters>
      <Header />
      <Grid container direction="row" alignItems="center" justifyContent="center" sx={{my: 3}}>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={100} />
        </Box>
        <CircularProgressWithLabel value={100} />
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{maxWidth: '500px'}}
        >
          <Grid item xs={12}>
            <Typography>Thank you.  We will reach out soon.</Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Container>
  );
}
